import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/zeit/b7146c2/components/layout-content.js";
import NewsletterSignup from '../components/newsletter-signup';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Components.ai`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/gradients"
        }}>{`/gradients`}</a></li>
    </ul>
    <h1 color='red'>Okay test</h1>
    <hr></hr>
    <h4>{`Sign up for the newsletter`}</h4>
    <p>{`If you want to be notified when new components and features
are added you can sign up for the newsletter or follow
`}<a parentName="p" {...{
        "href": "https://twitter.com/components_ai"
      }}>{`Components AI on Twitter`}</a>{`.`}</p>
    <NewsletterSignup mdxType="NewsletterSignup" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      